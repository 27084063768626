<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="seachStatus" status="my-orange" @click="showSearch(false)">隐藏查询</vxe-button>
        <vxe-button v-if="!seachStatus" status="my-orange" icon="fa fa-search" @click="showSearch(true)">查询</vxe-button>
        <vxe-button v-permission="'AddGlobalEvn'" status="my-orange" icon="fa fa-plus" @click="goPage('add')">添加</vxe-button>
      </template>
    </vxe-toolbar>
    <div v-if="seachStatus" class="search">
      <vxe-form title-align="right" title-width="120" title-colon :data="searchData" @submit="searchEvent" @reset="searchReset">
        <vxe-form-item title="数据分区Id" span="8">
          <vxe-input v-model="searchData.id" placeholder="数据分区Id" clearable />
        </vxe-form-item>
        <vxe-form-item title="主页面标题" span="8">
          <vxe-input v-model="searchData.mainPageTitle" placeholder="请输入主页面标题" clearable />
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <div class="table">
      <vxe-grid border stripe resizable auto-resize :columns="tableColumn" :data="tableData" :loading="loading">
        <template v-slot:operation="{ row }">
          <vxe-button v-permission="'AddGlobalEvn'" type="text" status="primary" @click="goPage('edit', row)">修改</vxe-button>
        </template>
      </vxe-grid>
      <!-- 分页 -->
      <div class="page">
        <vxe-pager
          :current-page.sync="tablePage.currentPage"
          :page-size.sync="tablePage.pageSize"
          :page-sizes="tablePage.pageSizes"
          :total="tablePage.totalResult"
          :layouts="layouts"
          @page-change="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      loading: false,
      seachStatus: false,
      searchData: {},
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      tableColumn: [
        {
          type: 'seq',
          title: '序号',
          width: 50,
          align: 'center'
        },
        {
          field: 'mainPageTitle',
          title: '主页标题',
          showOverflow: true,
          align: 'center',
          minWidth: '160'
        },
        {
          field: 'id',
          title: '数据分区Id',
          showOverflow: true,
          align: 'center',
          minWidth: '100'
        },
        {
          field: 'col1',
          title: '配置1',
          showOverflow: true,
          align: 'center',
          minWidth: '100'
        },
        {
          field: 'col2',
          title: '配置2',
          showOverflow: true,
          align: 'center',
          minWidth: '100'
        },
        {
          field: 'col3',
          title: '配置3',
          showOverflow: true,
          align: 'center',
          minWidth: '100'
        },
        {
          field: 'col4',
          title: '配置4',
          showOverflow: true,
          align: 'center',
          minWidth: '100'
        },
        {
          field: 'col5',
          title: '配置5',
          showOverflow: true,
          align: 'center',
          minWidth: '100'
        },
        {
          field: 'col6',
          title: '配置6',
          showOverflow: true,
          align: 'center',
          minWidth: '100'
        },
        {
          field: 'col7',
          title: '配置7',
          showOverflow: true,
          align: 'center',
          minWidth: '100'
        },
        {
          field: 'col8',
          title: '配置8',
          showOverflow: true,
          align: 'center',
          minWidth: '100'
        },
        {
          field: 'col9',
          title: '配置9',
          showOverflow: true,
          align: 'center',
          minWidth: '100'
        },
        {
          field: 'col10',
          title: '配置10',
          showOverflow: true,
          align: 'center',
          minWidth: '100'
        },
        {
          title: '操作',
          minWidth: '100',
          showOverflow: true,
          align: 'center',
          slots: {
            default: 'operation'
          }
        }
      ],
      tableData: []
    }
  },
  computed: {
    ...mapState(['layouts'])
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['getGlobalEvn']),
    initView() {
      this.getLists()
    },
    // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool
    },
    goPage(type, itemData) {
      console.log(itemData)
      this.$router.push({
        name: 'AddGlobalEvn',
        params: {
          type: type
        },
        query: {
          item: type == 'add' ? 'add' : JSON.stringify(itemData)
        }
      })
    },
    getLists() {
      this.loading = true
      const par = {
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          id: this.searchData.id,
          mainPageTitle: this.searchData.mainPageTitle
        }
      }
      this.getGlobalEvn(par).then((res) => {
        if (res.code == 200) {
          console.log(res)
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
        this.loading = false
      })
    },
    // 分页功能
    pageChange(item) {
      if (item.type == 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    },
    searchEvent() {
      console.log(this.searchData)
      this.seachStatus = false
      this.getLists()
    },
    searchReset() {
      this.searchData = {}
      this.getLists()
    }
  }
}
</script>

<style lang="scss" scoped>
.table{
	overflow-y:auto;
}
.wrapper {
  .datetime {
    display: flex;
    align-items: center;
    span {
      margin: 0 3px;
    }
  }
}
.sign i {
  margin-right: 3px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
